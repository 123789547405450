import React,{Suspense} from 'react';

// Importing all css files
// import "./styles/styles.css";
import "./styles/responsive.css";
import "./styles/style.css";
// import "./styles/certificate.css";
import "./styles/bootstrap.min.css";

import { BrowserRouter as Router,Routes,Route } from "react-router-dom";

// Importing all the required pages
// const Certificate = React.lazy(() => import('./pages/Certificate.jsx'));
import Certificate from './pages/Certificate';

function App() {
  return (
    <>
    <Router>
    {/* <Suspense fallback={<div></div>}> */}

      <Routes>
        <Route path="/" element={<Certificate/>}/>
      </Routes>
      {/* </Suspense> */}

    </Router>

    </>
  );
}

export default App;
